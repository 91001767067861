export const agentApiEndpoint = process.env.REACT_APP_AGENTAPIENDPOINT as string

export const cognitoConfig = {
	region: process.env.REACT_APP_REGION as string,
	userPoolId: process.env.REACT_APP_COGNITOPOOL as string,
	userPoolWebClientId: process.env.REACT_APP_COGNITOCLIENT as string,
}

export const fmHandlerArns = [
	{
		label: 'Content Server',
		name: process.env.REACT_APP_CONTENTSERVERHANDLER,
	}	
]


export const appsyncActionOutputs = [
	{
		label: 'Content Server API',
		endpoint: process.env.REACT_APP_CONTENTSERVERAPI,
	},
]

export const enableConfigureAgents = process.env.REACT_APP_ENABLECONSTRUCTINGAGENTS === 'true'