import { useSetRecoilState } from "recoil"
import { GraphqlQuery } from "../../invoker"
import { ConversationEvents, Conversations, Loadable } from "../state"

interface deleteConversationResponse {
    deleteConversation: {
        id: string
    }
}

interface deleteConversationHistoryResponse {
    deleteConversationHistory: {}
}

const deleteConversationQuery = new GraphqlQuery<deleteConversationResponse>(`
    mutation DeleteConversation($id: ID!) {
        deleteConversation(id: $id) {
            id
        }
    }
`)

const deleteConversationHistoryQuery = new GraphqlQuery<deleteConversationHistoryResponse>(`
    mutation DeleteConversationHistory($id: ID!) {
        deleteConversationHistory(id: $id) 
    }
`)

export function useAgentApiDeleteConversation () {
    const setConversationsValue = useSetRecoilState(Conversations)
    const setConversationEventsValue = useSetRecoilState(ConversationEvents)

    return (id: string) => {
        return deleteConversationQuery.invoke({id})
        .then(() => {
            deleteConversationHistoryQuery.invoke({id})
            .catch((error) => {
                console.error("DeleteConversationHistory Error: ", error);
            })
        })
        .then(() => {
            setConversationsValue(Loadable.unloaded())
            setConversationEventsValue({})
        })
    }

}
