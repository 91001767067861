import { Button, ColorMode, Flex, View, withAuthenticator } from "@aws-amplify/ui-react"
import { useNavigate } from "react-router-dom"
import { ThemeProvider, defaultDarkModeOverride } from '@aws-amplify/ui-react';
import { useState } from "react";
import { enableConfigureAgents } from "../endpoints";

const darkTheme = {
  name: 'my-theme',
  overrides: [defaultDarkModeOverride],
  tokens: {
    colors: {
        scarlet: {
            name: "--amplify-colors-scarlet-10",
            original: "#BA0C2F",
            path: [
                "colors",
                "scarlet",
                "10"
            ],
            value: "#BA0C2F"
        },
        gray: {
           20: {
                name: "--amplify-colors-gray-20",
                original: "#dfe3e5",
                path: [
                    "colors",
                    "gray",
                    "20"
                ],
                value: "#dfe3e5"
            },
            100: {
                name: "--amplify-colors-gray-100",
                original: "#bfc6cb",
                path: [
                    "colors",
                    "gray",
                    "100"
                ],
                value: "#bfc6cb"
            },
            160: {
                name: "--amplify-colors-gray-160",
                original: "#3f4443",
                path: [
                    "colors",
                    "gray",
                    "160"
                ],
                value: "#3f4443"
            },
            180: {
                name: "--amplify-colors-gray-180",
                original: "#212325",
                path: [
                    "colors",
                    "gray",
                    "180"
                ],
                value: "#212325"
            }
        }
    }
  }
};

function PageWrapper (props: any) {

    const nav = useNavigate()
    const [theme, setTheme] = useState<ColorMode>('light');

    const isChat = document.location.href.includes('/chat')
    const isLight = theme === 'light'

    const onTogglePage = () => {
        if (isChat)
            nav('/configuration')
        else 
            nav('/chat')
    }

    const onToggleTheme = () => {
        if (theme === 'dark')
            setTheme('light')
        else 
            setTheme('dark')
    }

    return (
        <ThemeProvider theme={darkTheme} colorMode={theme}>
            <View className="page" backgroundColor={isLight? 'rgb(247, 246, 246)' : 'black'}>
                <Flex className="themeTogglePanel">
                    {
                        enableConfigureAgents && (
                            <Button className="iconButton" onClick={onTogglePage}>
                                {
                                    !isChat && <i className="fa-solid fa-comment"></i>
                                }
                                {
                                    isChat && <i className="fa-solid fa-wrench"></i>
                                }
                            </Button>
                        )
                    }
                    <Button className="iconButton" onClick={onToggleTheme}>
                        {
                            !isLight && <i className="fa-solid fa-moon"></i>
                        }
                        {
                            isLight && <i className="fa-solid fa-sun"></i>
                        }
                    </Button>
                </Flex>
                {props.children}
            </View>
        </ThemeProvider>
    )
}

export default withAuthenticator(PageWrapper)